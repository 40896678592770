exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-template-tsx": () => import("./../../../src/templates/about-template.tsx" /* webpackChunkName: "component---src-templates-about-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-contact-template-tsx": () => import("./../../../src/templates/contact-template.tsx" /* webpackChunkName: "component---src-templates-contact-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-site-template-tsx": () => import("./../../../src/templates/site-template.tsx" /* webpackChunkName: "component---src-templates-site-template-tsx" */),
  "component---src-templates-tenant-template-tsx": () => import("./../../../src/templates/tenant-template.tsx" /* webpackChunkName: "component---src-templates-tenant-template-tsx" */)
}

